<template>
  <div class="flex items-center justify-center min-h-screen">
    <!-- <header class="container mx-auto">
      <img src="@/assets/logo.png" class="max-h-20" alt="HuskyTask" />
    </header> -->
    <slot />
  </div>
  <div class="flex bg-gray-100 shadow-sm p-2">
    <div class="mx-auto max-w-7xl w-full">
        <router-link class="inline-flex items-center py-2.5 transition duration-500 ease-in-out font-medium   px-7 text-base  focus:outline-none focus:ring-2 " to="/privacy-policy"> Privacy Policy </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  methods: {}
}
</script>
