const token = () => {
  var user = JSON.parse(localStorage.getItem('sa_user'))
  if (user && user.token) {
    return user.token
  }
  return null
}
const userId = () => {
  var user = JSON.parse(localStorage.getItem('sa_user'))
  if (user && user.id) {
    return user.id
  }
  return null
}

export { token, userId }
/* eslint no-multiple-empty-lines: "error" */

