<template>
  <router-link
    v-for="(item, index) in items"
    :key="index"
    class="flex items-center justify-between px-6 py-3 text-lg duration-200 border-l-4"
    :class="[$route.name === item.link ? activeClass : inactiveClass]"
    :to="{ name: item.link }">
  <span>
    <font-awesome-icon class="w-5 h-5" :icon="item.icon"></font-awesome-icon>
    <span class="mx-4">{{ item.title }}</span>
  </span>
    <font-awesome-icon class="w-5 h-5" :icon="faAngleRight"></font-awesome-icon>
  </router-link>

  <router-link
    to="#"
    @click="logout"
    class="flex items-center px-6 py-3 text-xl text-gray-800 duration-200 border-gray-900 lg:hidden"
  >
    <font-awesome-icon class="w-5 h-5" :icon="faLock"></font-awesome-icon>
    <span class="mx-4"> Log out </span>
  </router-link>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faUsers, faLock, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    FontAwesomeIcon
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const logout = async () => {
      await store.dispatch('auth/logout').then(
        () => {
          router.push({
            name: 'Login'
          })
        },
        (error) => {
          alert('error', error)
        }
      )
    }

    const activeClass = ref('bg-custom-green-light bg-opacity-50 text-custom-green-dark border-gray-100')
    const inactiveClass = ref(
      'text-gray-800 hover:bg-gray-100 hover:bg-opacity-25 hover:text-custom-green-dark'
    )

    const items = [
      {
        title: 'Search',
        link: 'Search',
        icon: faUsers
      },

      // {
      //   title: 'Messages',
      //   link: 'Messages',
      //   icon: faInbox
      // },
      {
        title: 'Profile',
        link: 'EditProfile',
        icon: faUser
      },
      {
        title: 'Privacy Policy',
        link: 'Privacy',
        icon: faLock
      }
    ]
    return {
      activeClass,
      inactiveClass,
      faAngleRight,
      items,
      faLock,
      logout
    }
  }
}
</script>
