<script>
export default {
  props: {
    isDark: {
      default: false,
      type: Boolean
    }
  }
}
</script>
<template>
  <img src="@/assets/logo-white.png" v-if="isDark" class="h-16" />
  <img src="@/assets/logo.png" v-else class="h-16" />
</template>
