
import axios from 'axios'
import { token } from './auth-header'

// const API_URL = 'http://localhost:8080/api/auth/';

class DataService {
  async getServices () {
    return await axios
      .get('service-cat-list', {
        headers: {
          Authorization: `Token ${token()}`
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }

  async searchProfile (id) {
    return await axios
      .get('profile-search/' + id, {
        headers: {
          Authorization: `Token ${token()}`
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }

  async sendMessage (data) {
    const formData = new FormData()
    formData.append('message', data.message)
    formData.append('selectUser', data.selectedUser)
    formData.append('user', data.user)
    return await axios
      .post('send-message', formData, {
        headers: {
          Authorization: `Token ${token()}`
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }

  async submitRating (data) {
    const formData = new FormData()
    formData.append('score', data.rating)
    formData.append('message', data.comment)
    formData.append('to_user', data.selectedUser)
    formData.append('from_user', data.user)
    return await axios
      .post('send-review', formData, {
        headers: {
          Authorization: `Token ${token()}`
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }
}

export default new DataService()
