import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import 'aos/dist/aos.css'
import router from './router'
import store from './store'

// axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'https://shielded-meadow-10836.herokuapp.com/api'
axios.defaults.baseURL = 'https://blooming-mountain-11200.herokuapp.com/api'

createApp(App).use(store).use(router).mount('#app')
