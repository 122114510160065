<template>
  <div class="flex h-screen bg-gray-200">
    <Sidebar />

    <div class="flex flex-col flex-1 overflow-hidden">
      <Header />

      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200" id="pageContent">
        <div class="px-2 sm:px-6 py-8 mx-auto lg:container">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line semi
import Header from '@/components/Header.vue';
// eslint-disable-next-line semi
import Sidebar from '@/components/Sidebar.vue';

export default {
  components: {
    Header,
    Sidebar
  }

}
</script>
