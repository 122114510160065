import AuthService from '../services/auth.service'

const user = JSON.parse(localStorage.getItem('sa_user'))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

const state = initialState
const getters = {
  getUser (state) {
    return state.user
  }
}
const actions = {
  async LogIn ({ commit }, user) {
    return AuthService.login(user).then(
      user => {
        commit('loginSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('loginFailure')
        return Promise.reject(error)
      }
    )
  },
  logout ({ commit }) {
    console.log('logout called')
    AuthService.logout()
    commit('logout')
  },
  async SignUp ({ commit }, user) {
    return AuthService.signup(user).then(
      user => {
        commit('registerSuccess', user)
        return Promise.resolve(user)
      },
      error => {
        commit('registerFailure')
        return Promise.reject(error)
      }
    )
  }

}
const mutations = {
  loginSuccess (state, user) {
    state.status.loggedIn = true
    state.user = user
  },
  loginFailure (state) {
    state.status.loggedIn = false
    state.user = null
  },
  logout (state) {
    state.status.loggedIn = false
    state.user = null
  },
  registerSuccess (state, user) {
    state.status.loggedIn = true
    state.user = user
  },
  registerFailure (state) {
    // state.status.loggedIn = false
    state.user = null
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
