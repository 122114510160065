<template>
  <div>
    <div class="relative w-full">
      <div class="absolute z-10 w-full">
        <div class="flex py-5 px-4 sm:px-10 justify-center w-full">
          <div class="flex-1">
            <router-link
            to="/"
            class="font-bold py-4 router-link-active router-link-exact-active text-4xl text-white"
          >
            <site-logo isDark />
          </router-link>
          </div>
          <div class="relative right-0 justify-center flex">
            <button type="button" class="text-white" @click="toggleNavigation">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <slot />
    <SidebarMenu :isOpen="isOpen" @toggleNavigation="toggleNavigation" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SidebarMenu from '@/components/SidebarMenu'
import SiteLogo from '@/components/SiteLogo'
const isOpen = ref(false)

const toggleNavigation = () => {
  isOpen.value = !isOpen.value
}
</script>
