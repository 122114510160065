<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useRouter } from 'vue-router'

import Frontend from '@/layout/Frontend.vue'

import AppLayout from '@/layout/AppLayout.vue'

import AuthLayout from '@/layout/AuthLayout.vue'
import GuestLayout from '@/layout/GuestLayout.vue'

export default {
  components: {
    Frontend,
    AppLayout,
    AuthLayout,
    GuestLayout
  },
  setup () {
    const { currentRoute } = useRouter()
    const layout = computed(() => currentRoute.value.meta.layout)
    return { layout }
  }
}
</script>
