
import axios from 'axios'
import { token } from './auth-header'

// const API_URL = 'http://localhost:8080/api/auth/';

class AuthService {
  login (user) {
    const formData = new FormData()
    formData.append('username', user.email)
    formData.append('password', user.password)
    return axios
      .post('auth', formData)
      .then(response => {
        if (response.data.token) {
          console.log('token', response.data)
          localStorage.setItem('sa_user', JSON.stringify(response.data))
        }
        console.log('res', response.data)
        return response.data
      })
  }

  async logout () {
    console.log('auth logouot serv')
    await axios.post('logout', null, {
      headers: {
        Authorization: `Token ${token()}`
      }
    }).then(
      () => {
        localStorage.removeItem('sa_user')
        // location.reload()
      },
      () => {
        localStorage.removeItem('sa_user')
        // location.reload()
      }
    )
  }

  signup (user) {
    const formData = new FormData()
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('address_line_1', user.address_line_1)
    formData.append('phone_number', user.phone_number)
    formData.append('address_line_2', user.address_line_2)
    formData.append('city', user.city)
    formData.append('postal_code', user.postal_code)
    formData.append('province', user.province)
    formData.append('email', user.email)
    formData.append('password', user.password)
    return axios
      .post('register', formData)
      .then(response => {
        console.log('token', response.data)
        if (response.data.token) {
          localStorage.setItem('sa_user', JSON.stringify(response.data))
        }
        console.log('res', response.data)
        return response.data
      })
  }
}

export default new AuthService()
