import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   meta: {
  //     layout: 'Frontend'
  //   }
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
    meta: {
      layout: 'GuestLayout'
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue'),
    meta: {
      layout: 'Frontend'
    }
  },
  {
    path: '/join-wait-list',
    name: 'JoinWaitList',
    component: () => import(/* webpackChunkName: "waitinglist" */ '../views/WaitingList.vue'),
    meta: {
      layout: 'AuthLayout'
    }
  },
  {
    path: '/verified',
    name: 'Verified',
    component: () => import(/* webpackChunkName: "verifiedsuccess" */ '../views/VerifiedSuccess.vue'),
    meta: {
      layout: 'AuthLayout'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      layout: 'AuthLayout'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      layout: 'AuthLayout'
    }
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import(/* webpackChunkName: "registersuccess" */ '../views/RegisterSuccess.vue'),
    meta: {
      layout: 'AuthLayout'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import(/* webpackChunkName: "edit-profile" */ '../views/EditProfile.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/identity-verification',
    name: 'VerifyId',
    component: () => import(/* webpackChunkName: "verify-id" */ '../views/VerifyId.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import(/* webpackChunkName: "edit-profile" */ '../views/Messages.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/post-review/:id',
    name: 'PostReview',
    component: () => import(/* webpackChunkName: "post-review" */ '../views/PostReview.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      layout: 'AppLayout'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/Privacy.vue'),
    meta: {
      layout: 'Frontend'
    }
  },
  {
    path: '/tasker',
    name: 'Tasker',
    component: () => import(/* webpackChunkName: "taskerlanding" */ '../views/TaskerLanding.vue'),
    meta: {
      layout: 'LandingLayout'
    }
  },
  {
    path: '/owner',
    name: 'Owner',
    component: () => import(/* webpackChunkName: "ownerlanding" */ '../views/OwnerLanding.vue'),
    meta: {
      layout: 'LandingLayout'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/success', '/', '/verified', '/privacy-policy', '/join-wait-list', '/tasker', '/owner']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('sa_user')

  // redirect to login page
  if (authRequired && !loggedIn) {
    console.log('page', authRequired)
    next('/login')
    // next()
  } else {
    next()
  }
})

export default router
