<template>
  <div>
    <header class="container relative z-20 px-5 mx-auto md:max-w-5xl lg:max-w-7xl">
    <div class="flex justify-center md:inline-block">
      <router-link to="/"><img src="@/assets/logo.png" class="mt-4 max-h-20" alt="HuskyTask"
      /></router-link>
    </div>
    </header>
    <slot />
  </div>
</template>
<script>
export default {
  data () {
    return {
      title: 'front'
    }
  },
  methods: {}
}
</script>
