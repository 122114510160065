<template>
  <header
    class="flex items-center justify-between px-6 py-4 border-b-4 shadow bg-custom-green border-custom-green-dark"
  >
    <div class="flex items-center">
      <button
        @click="toggleMenu"
        class="text-white focus:outline-none lg:hidden"
      >
        <svg
          class="w-6 h-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>

    <div class="flex items-center">
      <div class="relative hidden lg:block">
        <button
          @click="dropdownOpen = !dropdownOpen"
          class="relative z-10 w-10 h-10 overflow-hidden text-gray-700 bg-white rounded-full focus:outline-none"
        >
          <font-awesome-icon size="lg" :icon="faUser"></font-awesome-icon>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 z-10 w-full h-full"
        ></div>

        <div
          v-show="dropdownOpen"
          class="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl "
        >

        <router-link
        to="/identity-verification"
        class="block px-4 py-2 text-sm text-gray-700 hover:bg-custom-green hover:text-white"
        >Identity Verify</router-link
      >
          <router-link
            to="#"
            @click="logout"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-custom-green hover:text-white"
            >Log out</router-link
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from '@vue/reactivity'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { FontAwesomeIcon },
  setup () {
    const dropdownOpen = ref(false)
    const store = useStore()
    const router = useRouter()
    const isOpen = store.state.data.openSidebar // store.getters['data/sidebarStatus'] // ("data/getUserProfile");

    const toggleMenu = async () => {
      // console.log('hello')
      await store.dispatch('data/toggleSidebar')
    }
    const logout = async () => {
      await store.dispatch('auth/logout').then(
        () => {
          router.push({
            name: 'Login'
          })
        },
        (error) => {
          alert('Error', error)
        }
      )
    }
    return {
      isOpen,
      faUser,
      toggleMenu,
      dropdownOpen,
      logout
    }
  }
}
</script>
