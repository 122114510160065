
import axios from 'axios'
import { token, userId } from './auth-header'

// const API_URL = 'http://localhost:8080/api/auth/';

class UserService {
  // console.log(token())
  async getProfile () {
    return await axios
      .get('profile', {
        headers: {
          Authorization: `Token ${token()}`
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
      .catch(function (error) {
        if (error.response && error.response.status) {
          return {}
        }
      })
  }

  async updateProfile (profile) {
    profile.user = userId()
    return await axios
      .put('/profile', profile, {
        headers: {
          Authorization: `Token ${token()}`,
          'Content-Type': 'application/json',
          'content-type': 'application/json'
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }

  async verifyIdentity (data) {
    return await axios
      .put('/id-validate', data, {
        headers: {
          Authorization: `Token ${token()}`,
          'Content-Type': 'application/json',
          'content-type': 'application/json'
        }
      })
      .then(response => {
        console.log(response)
        return response.data
      })
  }
}

export default new UserService()
