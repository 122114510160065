<template>
  <div class="flex">
    <!-- Backdrop -->
    <div
      :class="store.state.data.openSidebar ? 'block' : 'hidden'"
      @click="toggleSidebar"
      class="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
    ></div>
    <!-- End Backdrop -->

    <div
      :class="store.state.data.openSidebar ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'"
      class="fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-white lg:translate-x-0 lg:static lg:inset-0"
    >
      <div class="flex items-center justify-center mt-3">
        <div class="flex items-center">
          <router-link to="/search">
            <img class="w-auto h-12 sm:h-16" :src="logo" alt="" />
          </router-link>
        </div>
      </div>

      <nav class="mt-10">
        <navigation />
      </nav>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Navigation from './Navigation'

export default {
  components: { Navigation },
  setup () {
    // const isOpen = reactive(false)
    const store = useStore()
    const logo = ref(require('@/assets/logo.png'))
    const toggleSidebar = async () => {
      await store.dispatch('data/toggleSidebar')
    }
    return {
      store,
      toggleSidebar,
      logo
    }
  }
}
</script>
